import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Footer
 *
 * @property {*} prop Prop comment.
 *
 * @class Footer
 * @extends {Component}
 */
class Footer extends Component {
    static propTypes = {
        data: PropTypes.shape({
            copyright: PropTypes.array,
            partner: PropTypes.array,
            socialMedia: PropTypes.array
        }).isRequired
    }

    /**
     * Handle the survey tracking.
     *
     * @memberof Footer
     */
    @autobind
    handleTrack() {
        window.dataLayer.push({
            event: 'surveyInit',
            PageTitle: document.title,
            URL: window.location.href
        });
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Footer
     */
    render() {
        const {data: {copyright, partner, socialMedia}} = this.props;

        return (
            <FooterElement>
                <SocialBar>
                    <ContentBox>
                        {socialMedia && socialMedia.map(social => {
                            const {icon: {alt, publicPath, title}, link} = social;

                            return (
                                <Link key={link} href={link} passHref>
                                    <LinkBox rel="noopener noreferrer" target="_blank">
                                        <SocialIcon alt={alt} src={publicPath.src} title={title} />
                                    </LinkBox>
                                </Link>
                            );
                        })}
                    </ContentBox>
                </SocialBar>
                {(partner !== null && (Array.isArray(partner) && partner.length > 0)) && (
                    <PartnerBar>
                        <ContentBox>
                            {partner.map(partnerImg => {
                                const {alt, publicPath, title} = partnerImg;

                                return (
                                    <ImageContainer key={publicPath.src}>
                                        <Image
                                            alt={alt}
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition="center center"
                                            src={publicPath.src}
                                            title={title}
                                        />
                                    </ImageContainer>
                                );
                            })}
                        </ContentBox>
                    </PartnerBar>
                )}
                <CopyRightBar>
                    <CopyRightBox>
                        <div>
                            <Links>
                                {copyright && copyright.map(link => {
                                    const {linkName, page: {seoSlug}} = link;

                                    return (
                                        <Link key={seoSlug} href={`/${seoSlug}`} passHref>
                                            <FooterLink>{linkName}</FooterLink>
                                        </Link>
                                    );
                                })}
                                <Link href="/interview" passHref>
                                    <FooterLink onClick={this.handleTrack}>Zweithaarsalon finden</FooterLink>
                                </Link>
                            </Links>
                        </div>
                        <Copyright>&copy; {new Date().getFullYear()} Mein Neues Haar, All rights reserved</Copyright>
                    </CopyRightBox>
                </CopyRightBar>
            </FooterElement>
        );
    }
}

export default Footer;

const FooterElement = styled.div`
    width: 100%;
`;

const SocialBar = styled.div`
    background-color: #627068;
    padding: ${clampCss(20, 'm')} 0 ${clampCss(16, 'm')} 0;
    text-align: center;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        padding: ${clampCss(21, 't')} 0 ${clampCss(19, 't')} 0;
    }

    ${MEDIA.DESKTOP.UP} {
        padding: max(21px, ${pxToVw(21, 'd')}) 0 max(19px, ${pxToVw(19, 'd')}) 0;
    }
`;

const LinkBox = styled.a``;

const SocialIcon = styled.img`
    height: ${clampCss(24, 'm')};
    width: ${clampCss(24, 'm')};

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(24, 't')};
        width: ${clampCss(24, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(24px, ${pxToVw(24, 'd')});
        width: max(24px, ${pxToVw(24, 'd')});
    }
`;

const PartnerBar = styled.div`
    background-color: #2e3542;
    display: none;
    text-align: center;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        display: block;
        padding: ${clampCss(77, 't')} 0 ${clampCss(21, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        padding: max(77px, ${pxToVw(77, 'd')}) 0 max(21px, ${pxToVw(21, 'd')});
    }
`;

const ImageContainer = styled.div`
    position: relative;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(130, 't')};
        width: ${clampCss(190, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(130px, ${pxToVw(130, 'd')});
        width: max(190px, ${pxToVw(190, 'd')});
    }
`;

const CopyRightBar = styled.div`
    background-color: #2e3542;
    padding-bottom: ${clampCss(40, 'm')};
    padding-top: ${clampCss(45, 'm')};
    text-align: center;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        padding-bottom: ${clampCss(56, 't')};
        padding-top: ${clampCss(56, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        padding-bottom: max(56px, ${pxToVw(56, 'd')});
        padding-top: max(56px, ${pxToVw(56, 'd')});
    }
`;

const ContentBox = styled.div`
    --gap: ${clampCss(48, 'm')};
    align-items: center;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    margin-bottom: 0;
    margin-left: calc(-1 * var(--gap));
    margin-top: calc(-1 * var(--gap));
    max-width: calc(100% + var(--gap));
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    & > * {
        margin-left: var(--gap);
        margin-top: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(48, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(48px, ${pxToVw(48, 'd')});
        padding: 0 15px;
        width: max(1320px, ${pxToVw(1320, 'd')});
    }
`;

const CopyRightBox = styled.div`
    --gap: ${clampCss(32, 'm')};
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    margin-left: calc(-1 * var(--gap));
    margin-top: calc(-1 * var(--gap));
    max-width: calc(100% + var(--gap));
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    & > * {
        margin-left: var(--gap);
        margin-top: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(32, 't')};
        justify-content: center;
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: 0;
        justify-content: space-between;
        max-width: calc(100% + var(--gap));
        padding: 0 15px;
        width: max(1320px, ${pxToVw(1320, 'd')});
    }
`;

const Links = styled.div`
    --gap: ${clampCss(12, 'm')};
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: calc(-1 * var(--gap));
    margin-top: calc(-1 * var(--gap));

    & > * {
        margin-left: var(--gap);
        margin-top: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(40, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(40px, ${pxToVw(40, 'd')});
    }
`;

const FooterLink = styled.a`
    ${FONTS.REGULAR}
    color: ${({theme}) => theme.colors.light100};
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    text-decoration: none;
    text-transform: uppercase;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
    }
`;

const Copyright = styled.div`
    ${FONTS.REGULAR}
    color: ${({theme}) => theme.colors.light100};
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
    }
`;