import React, {Component} from 'react';

import {AnimatePresence, motion} from 'framer-motion';
import Head from 'next/head';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Footer from 'Frontend/components/Footer';
import Menu from 'Frontend/components/Menu';

const Transition = {
    enter: {opacity: 1},
    exit: {opacity: 0},
    initial: {opacity: 0}
};

/**
 * PageLayout
 *
 * @property {*} prop Prop comment.
 *
 * @class PageLayout
 * @extends {Component}
 */
class PageLayout extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        router: PropTypes.object.isRequired,
        footer: PropTypes.object,
        isColumn: PropTypes.bool,
        isLightMode: PropTypes.bool,
        menu: PropTypes.object,
        page: PropTypes.shape({
            seoDescription: PropTypes.string,
            seoKeywordList: PropTypes.array,
            // eslint-disable-next-line react/boolean-prop-naming
            seoNoFollow: PropTypes.bool,
            // eslint-disable-next-line react/boolean-prop-naming
            seoNoIndex: PropTypes.bool,
            seoPageTitle: PropTypes.string
        })
    }

    static defaultProps = {
        footer: null,
        isColumn: false,
        isLightMode: false,
        menu: null,
        page: {
            seoDescription: '',
            seoKeywordList: [],
            seoNoFollow: false,
            seoNoIndex: false,
            seoPageTitle: ''
        }
    }

    /**
     * Generates the head tags.
     *
     * @returns {JSX} The head component.
     * @memberof ContentPages
     */
    renderHeadTags() {
        const {page: {seoDescription, seoKeywordList, seoNoFollow, seoNoIndex, seoPageTitle}} = this.props;
        const robots = [];

        robots.push(seoNoFollow ? 'nofollow' : 'follow');
        robots.push(seoNoIndex ? 'noindex' : 'index');

        return (
            <Head>
                <title>{seoPageTitle}</title>
                <meta content="initial-scale=1.0, width=device-width" name="viewport" />
                <meta content={seoDescription} name="description" />
                <meta content={seoKeywordList.join(', ')} name="keywords" />
                <meta content={robots.join(', ')} name="robots" />
            </Head>
        );
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof PageLayout
     */
    render() {
        const {
            children,
            footer,
            isColumn,
            isLightMode,
            menu,
            router
        } = this.props;

        return (
            <PageContainer>
                {this.renderHeadTags()}
                <Menu isLightMode={isLightMode} menuItems={menu} />
                <AnimatePresence initial={false} exitBeforeEnter>
                    <motion.div
                        key={router.asPath}
                        animate="enter"
                        exit="exit"
                        initial="initial"
                        style={{
                            flex: '1 1 auto',
                            height: '100%',
                            ...((isColumn) ? {
                                display: 'flex',
                                flexDirection: 'column'
                            } : {})
                        }}
                        variants={Transition}
                    >
                        {children}
                    </motion.div>
                </AnimatePresence>
                {footer.copyright && <Footer data={footer} />}
            </PageContainer>
        );
    }
}

export default PageLayout;

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
`;