import React, {Component} from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Arrow} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Gets the right color for the right state on desktop.
 *
 * @param {Object}  props                   The props for the item.
 * @param {Boolean} props.active            The active state.
 * @param {Boolean} props.isLightMode The darkBackground state.
 * @param {Boolean} props.scrolled          The scrolled state.
 * @param {Object}  props.theme             The color theme.
 *
 * @returns {String} The color string.
 */
const getRightColor = ({isLightMode, scrolled, theme}) => {
    if (scrolled) {
        return theme.colors.textColor;
    } else if (isLightMode) {
        return theme.colors.light100;
    }

    return theme.colors.textColor;
};

/**
 * MenuItem
 *
 * @property {*} prop Prop comment.
 *
 * @class MenuItem
 * @extends {Component}
 */
class MenuItem extends Component {
    static propTypes = {
        activeRoute: PropTypes.string,
        isLightMode: PropTypes.bool,
        isScrolled: PropTypes.bool,
        itemData: PropTypes.shape({
            linkName: PropTypes.string.isRequired,
            page: PropTypes.shape({seoSlug: PropTypes.string.isRequired}).isRequired,
            subPages: PropTypes.array
        }),
        onClick: PropTypes.func
    }

    static defaultProps = {
        activeRoute: '',
        isLightMode: false,
        isScrolled:  false,
        itemData: {subPages: []},
        onClick() {}
    }

    /**
     * Checks if the menu item is active or not.
     *
     * @param {String} seoSlug  The Slug of the item.
     * @param {Array}  [subPages=[]] The subpages of the item if any.
     *
     * @returns {Boolean} If its active or not.
     * @memberof MenuItem
     */
    getActiveState(seoSlug, subPages = []) {
        const {activeRoute} = this.props;

        return (activeRoute === seoSlug || subPages.some(item => item.page.seoSlug === activeRoute));
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof MenuItem
     */
    render() {
        const {
            isLightMode,
            isScrolled,
            itemData: {linkName, page: {seoSlug}, subPages},
            onClick: handleClick
        } = this.props;

        return (
            <MenuItemElement>
                <Link href={`/${seoSlug}`} passHref>
                    <LinkStyle
                        active={this.getActiveState(seoSlug, subPages)}
                        isLightMode={isLightMode}
                        scrolled={isScrolled}
                        onClick={handleClick}
                    >
                        {linkName}
                    </LinkStyle>
                </Link>
                {(subPages && subPages.length > 0) && (
                    <Flyout>
                        {subPages.map(item => {
                            const {linkName: subName, page: {seoSlug: subUrl}} = item;

                            return (
                                <Link key={subName} href={`/${subUrl}`} passHref>
                                    <SubLinkStyle
                                        active={this.getActiveState(subUrl)}
                                        onClick={handleClick}
                                    >
                                        {subName}
                                    </SubLinkStyle>
                                </Link>
                            );
                        })}
                    </Flyout>
                )}
            </MenuItemElement>
        );
    }
}

export default MenuItem;

const Flyout = styled.div`
    flex-direction: column;
    padding: ${clampCss(12, 'm')} 0 0 0;

    ${MEDIA.DESKTOP.UP} {
        background: ${({theme}) => theme.colors.light100};
        border: 1px solid ${({theme}) => theme.colors.lightBorder};
        display: none;
        left: 50%;
        padding: ${pxToVw(12, 'd')} ${pxToVw(48, 'd')} ${pxToVw(12, 'd')} ${pxToVw(24, 'd')};
        position: absolute;
        top: 100%;
        transform: translate(-50%, 0);
        width: ${pxToVw(211, 'd')};
    }
`;

const MenuItemElement = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: ${clampCss(16, 'm')} 0 ${clampCss(16, 'm')} ${clampCss(41, 'm')};
    position: relative;

    ${MEDIA.DESKTOP.UP} {
        align-items: center;
        flex-direction: row;
        padding: 0;

        &:hover > ${Flyout} {
            display: flex;
        }
    }
`;

const LinkStyle = styled.a`
    ${({active}) => (active ? FONTS.BOLD : FONTS.SEMIBOLD)}
    align-items: center;
    color: ${({theme}) => theme.colors.textColor};
    cursor: pointer;
    display: flex;
    font-size: ${clampCss(12, 'm')};
    height: 100%;
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s 0s ease-in-out;

    &:hover {
        color: ${({theme}) => theme.colors.textHighlight};
        transition: color 0.3s 0s ease-in-out;
    }

    ${MEDIA.DESKTOP.UP} {
        color: ${getRightColor};
        font-size: max(12px, ${pxToVw(12, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
    }
`;

const SubLinkStyle = styled.a`
    ${({active}) => (active ? FONTS.SEMIBOLD : FONTS.REGULAR)}
    color: ${({theme}) => theme.colors.textColor};
    cursor: pointer;
    display: block;
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    padding: ${clampCss(12, 'm')} 0 ${clampCss(12, 'm')} ${clampCss(21, 'm')};
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s 0s ease-in-out, padding 0.2s 0s ease-in-out;
    will-change: color, padding;

    &::before {
        background-image: url(${iconAsBackground(Arrow)});
        background-position: right;
        background-size: ${clampCss(24, 'm')} 100%;
        content: '';
        height: ${clampCss(12, 'm')};
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        transition: width 0.2s 0s ease-in-out;
        width: ${clampCss(12, 'm')};
        will-change: width;
    }

    &:hover {
        color: ${({theme}) => theme.colors.textHighlight};
        padding: ${clampCss(12, 'm')} 0 ${clampCss(12, 'm')} ${clampCss(33, 'm')};
        transition: color 0.2s 0s ease-in-out, padding 0.2s 0s ease-in-out;
        will-change: color, padding;

        &::before {
            background-image: ${({theme}) => `url(${iconAsBackground(Arrow, {color: theme.colors.textHighlight})})`};
            transition: width 0.2s 0s ease-in-out;
            width: ${clampCss(24, 'm')};
            will-change: width;
        }
    }

    &:last-child {
        margin-bottom: ${clampCss(-12, 'm', true)};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(12px, ${pxToVw(12, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
        padding: ${pxToVw(12, 'd')} 0 ${pxToVw(12, 'd')} ${pxToVw(21, 'd')};

        &::before {
            background-size: ${pxToVw(24, 'd')} 100%;
            height: ${pxToVw(12, 'd')};
            width: ${pxToVw(12, 'd')};
        }

        &:hover, &:active{
            padding: ${pxToVw(12, 'd')} 0 ${pxToVw(12, 'd')} ${pxToVw(33, 'd')};

            &::before {
                width: ${pxToVw(24, 'd')};
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;