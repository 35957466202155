import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuItem from 'Frontend/components/MenuItem';

import {Logo as LogoIcon} from 'Frontend/assets/images/icons';
import {theme} from 'Frontend/utils/globalStyles';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Gets the right color for the right state on desktop.
 *
 * @param {Object}  props                   The props for the item.
 * @param {Boolean} props.active            The active state.
 * @param {Boolean} props.isLightMode The darkBackground state.
 * @param {Boolean} props.scrolled          The scrolled state.
 * @param {Object}  props.theme             The color theme.
 *
 * @returns {String} The color string.
 */
const getRightColor = ({active, isLightMode, scrolled, theme: t}) => {
    if (scrolled) {
        return t.colors.textColor;
    } else if (isLightMode && !active) {
        return t.colors.light100;
    }

    return t.colors.textColor;
};

/**
 * Menu
 *
 * @property {*} prop Prop comment.
 *
 * @class Menu
 * @extends {Component}
 */
class Menu extends Component {
    static propTypes = {
        isLightMode: PropTypes.bool,
        menuItems: PropTypes.shape({
            active: PropTypes.string,
            isQuickLead: PropTypes.bool,
            mainMenu: PropTypes.array
        })
    }

    static defaultProps = {
        isLightMode: false,
        menuItems: {
            active: '/',
            isQuickLead: false,
            mainMenu: []
        }
    }

    /**
     * Creates an instance of Menu.
     *
     * @param {Object} props Component props.
     * @memberof Menu
     */
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            scrolled: false
        };
    }

    /**
     * Adds an onscroll handler.
     *
     * @memberof Menu
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    /**
     * Removes the onscroll handler.
     *
     * @memberof Menu
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Handles the scroll event to change Menu bar appearance.
     *
     * @memberof Menu
     */
    @autobind
    handleScroll() {
        const {scrolled} = this.state;

        if (window.scrollY === 0 && scrolled) {
            this.setState({scrolled: false});
        } else if (window.scrollY > 0 && !scrolled) {
            this.setState({scrolled: true});
        }
    }

    /**
     * Toggles the menu state between open and close.
     *
     * @memberof Menu
     */
    @autobind
    handleMenu() {
        this.setState(oldState => ({open: !oldState.open}));
    }

    /**
     * Closes the menu.
     *
     * @memberof Menu
     */
    @autobind
    handleMenuClose() {
        this.setState({open: false});
    }

    /**
     * Handles interview tracking.
     *
     * @memberof Menu
     */
    @autobind
    handleTrack() {
        this.handleMenuClose();

        window.dataLayer.push({
            event: 'surveyInit',
            PageTitle: document.title.innerText,
            URL: window.location.href
        });
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Menu
     */
    render() {
        const {open, scrolled} = this.state;
        const {
            isLightMode,
            menuItems: {active, isQuickLead, mainMenu = []}
        } = this.props;
        let logo = iconAsBackground(LogoIcon);

        if (isLightMode && !scrolled) {
            logo = iconAsBackground(LogoIcon, {color: theme.colors.light100});
        }

        const interviewLink = isQuickLead ? 'interview-short' : 'interview';

        return (
            <MenuElement scrolled={scrolled}>
                <CenterContainer>
                    <Link href="/">
                        <Logo logo={logo} />
                    </Link>
                    <BackDrop open={open} />
                    <MenuList open={open}>
                        {mainMenu.length > 0 && (
                            <>
                                {mainMenu.map(item => (
                                    <MenuItem
                                        key={item.linkName}
                                        activeRoute={active}
                                        isLightMode={isLightMode}
                                        isScrolled={scrolled}
                                        itemData={item}
                                        onClick={this.handleMenuClose}
                                    />
                                ))}
                                <MenuItem
                                    activeRoute={active}
                                    isLightMode={isLightMode}
                                    isScrolled={scrolled}
                                    itemData={{
                                        linkName: 'Zweithaarstudio finden',
                                        page: {seoSlug: interviewLink}
                                    }}
                                    onClick={this.handleMenuClose}
                                />
                            </>
                        )}
                    </MenuList>
                    {mainMenu.length > 0 && (
                        <MenuButton
                            active={open}
                            isLightMode={isLightMode}
                            scrolled={scrolled}
                            onClick={this.handleMenu}
                        />
                    )}
                </CenterContainer>
            </MenuElement>
        );
    }
}

export default Menu;

const MenuElement = styled.div`
    background: ${({scrolled, theme: t}) => (scrolled ? t.colors.light100 : 'transparent')};
    border-bottom: ${({scrolled}) => (scrolled ? '1px' : '0px')} solid ${({theme: t}) => t.colors.lightBorder};
    display: flex;
    height: ${clampCss(56, 'm')};
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: background 0.1s 0s ease-in-out, border 0.1s 0s ease-in-out, height 0.1s 0s ease-in-out;
    width: 100%;
    z-index: 100;

    ${MEDIA.DESKTOP.UP} {
        height: ${({scrolled}) => (scrolled ? `max(87px, ${pxToVw(87, 'd')})` : `max(124px, ${pxToVw(124, 'd')})`)};
    }
`;

const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.DESKTOP.UP} {
        width: max(1320px, ${pxToVw(1320, 'd')});
    }
`;

const Logo = styled.div`
    background: url(${({logo}) => logo});
    background-size: 100% 100%;
    cursor: pointer;
    height: ${clampCss(37, 'm')};
    transition: background 0.1s 0s ease-in-out;
    width: ${clampCss(73, 'm')};

    ${MEDIA.DESKTOP.UP} {
        height: max(48px, ${pxToVw(48, 'd')});
        width: max(94px, ${pxToVw(94, 'd')});
    }
`;

const BackDrop = styled.div`
    display: none;

    ${MEDIA.TABLET.UP} {
        background: rgba(0, 0, 0, 0.8);
        display: block;
        height: 100vh;
        left: 0;
        opacity: ${({open}) => (open ? '1' : '0')};
        pointer-events: ${({open}) => (open ? 'auto' : 'none')};
        position: absolute;
        top: 0;
        transition: opacity 0.3s 0s ease-in-out;
        width: 100vw;
    }

    ${MEDIA.DESKTOP.UP} {
        display: none;
    }
`;

const MenuList = styled.div`
    background-color: ${({theme: t}) => t.colors.light100};
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    left: ${({open}) => (open ? '0%' : '100%')};
    padding: ${clampCss(10, 'm')} 0 0 0;
    position: absolute;
    top: 0;
    transition: left 0.3s 0s ease-in-out;
    width: 100vw;

    ${MEDIA.TABLET.UP} {
        left: initial;
        right: ${({open}) => (open ? '0%' : '-55%')};
        transition: right 0.3s 0s ease-in-out;
        width: 55vw;
    }

    ${MEDIA.DESKTOP.UP} {
        background-color: transparent;
        flex-direction: row;
        height: 100%;
        justify-content: space-between;
        left: 0;
        padding: 0;
        position: relative;
        width: max(1100px, ${pxToVw(1100, 'd')});
    }
`;

const MenuButton = styled.div`
    height: ${clampCss(24, 'm')};
    position: relative;
    width: ${clampCss(24, 'm')};
    z-index: 110;

    &::before {
        background: ${getRightColor};
        content: '';
        height: 2px;
        left: 0px;
        position: absolute;
        top: calc(50% - 1px);
        width: 100%;
        transform: ${({active}) => (active ? 'translate(0, 0) rotate(-45deg)' : `translate(0, ${clampCss(-4, 'm', true)}) rotate(0deg)`)};
        transition: transform 0.2s 0s ease-in-out;
    }

    &::after {
        background: ${getRightColor};
        content: '';
        height: 2px;
        left: 0px;
        position: absolute;
        bottom: calc(50% - 1px);
        width: 100%;
        transform: ${({active}) => (active ? 'translate(0, 0) rotate(45deg)' : `translate(0, ${clampCss(4, 'm')}) rotate(0deg)`)};
        transition: transform 0.2s 0s ease-in-out;
    }

    ${MEDIA.DESKTOP.UP} {
        display: none;
    }
`;